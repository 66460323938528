@keyframes swim {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100vw);
    }
}

.fish {
    position: fixed;
    width: 150px; /* Adjust based on the size of your fish image */
    animation: swim linear infinite;
}

.fish img {
    width: 100%;
    height: auto;
}

.App{
    /* width: 100vw; */
    height: 100vh;
    background-image: url('https://cdn.dribbble.com/userupload/7201430/file/original-d6f2dc842a3e5bdc1c4c30d6b7bc40f5.gif');
    background-size: contain;
}